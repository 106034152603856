import $ from 'jquery'
import { immer } from 'zustand/middleware/immer'
import { createStore } from 'zustand/vanilla'

type Store = {
  stack: number
}

type StoreAction = {
  increment(): void
  decrement(): void
}

const slideoutStore = createStore<Store & StoreAction>()(
  immer((set) => ({
    stack: 0,
    increment() {
      set((current) => {
        current.stack += 1
      })
    },
    decrement() {
      set((current) => {
        current.stack -= 1
      })
    },
  })),
)

function slideout(element: JQuery) {
  setTimeout(function () {
    const store = slideoutStore.getState()
    element.addClass('active')

    if (store.stack >= 1) {
      element.addClass('stack')
    }

    store.increment()
  }, 0)

  element.on('click', function (event) {
    if (event.target !== element.get(0)) {
      return
    }

    slideoutClose(element)
  })
}

function slideoutClose(element: JQuery, remove = true) {
  slideoutStore.getState().decrement()
  element.removeClass('active')

  if (remove) {
    setTimeout(function () {
      element.remove()
    }, 300)
  }
}

slideout.close = slideoutClose

$(function () {
  slideoutStore.subscribe((state) => {
    switch (state.stack) {
      case 0:
        $(document.body).removeClass('no-scroll')
        break
      case 1:
        $(document.body).addClass('no-scroll')
        break
    }
  })
})

export { slideout }
