import alpn from 'alpinejs'
import htmx from 'htmx.org'
import jqry from 'jquery'
import { createIcons, icons } from 'lucide'
import { slideout } from './components/slideout'

declare global {
  interface Window {
    $: typeof jqry
    htmx: typeof htmx
    slideout: typeof slideout
    Alpine: typeof alpn
  }
}

window.$ = jqry
window.htmx = htmx
window.slideout = slideout
window.Alpine = alpn

$(function () {
  htmx.config.scrollBehavior = 'auto'
  htmx.config.settlingClass = 'settling'
  htmx.config.swappingClass = 'swapping'

  createIcons({ icons, attrs: { 'stroke-width': 1.25 } })
  alpn.start()

  htmx.on('htmx:afterSwap', function () {
    createIcons({ icons, attrs: { 'stroke-width': 1.25 } })
  })
})
